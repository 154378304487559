.questions {
  padding-bottom: 100px;

  @media (max-width: 968px) {
    padding-bottom: 50px;
  }

  &__content {
    display: flex;
    align-items: stretch;
    gap: 10px;
  }

  &__photo {
    max-width: 555px;

    @media (max-width: 1175px) {
      display: none;
    }
  }
}

.footer {
  padding-bottom: 50px;

  &__content {
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__row {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 25px;
    }
  }

  &__date {
    display: flex;
    align-items: center;
    gap: 50px;

    @media (max-width: 600px) {
      flex-direction: column;
      gap: 25px;
      align-items: center;
      justify-content: center;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__information {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (max-width: 600px) {
      align-items: center;
      justify-content: center;
    }
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: var(--dark-color);
  }

  &__number {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: var(--dark-color);
  }

  &__mail {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: var(--dark-color);
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    line-height: 157%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: var(--dark-color);
  }

  &__copyright {
    max-width: 1028px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: rgba(51, 51, 51, 0.5);

    @media (max-width: 1024px) {
      text-align: center;
    }

  }

  &__buttons {
    display: flex;
    gap: 10px;

    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
}

:root {
  --font-family: "SF Pro Display", sans-serif;
  --third-family: "Inter", sans-serif;
  --font4: "Manrope", sans-serif;
  --content-width: 1160px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  --white-color: #fff;
  --black-color: #000;
  --light-color: #f4f4f4;
  --dark-color: #333;
  --accent-color: #1a2555;
}

.advantages {

  display: flex;
  align-items: center;
  gap: 19px;

  @media (max-width: 1000px) {
    justify-content: center;
  }

  @media (max-width: 620px) {
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: stretch;

    &--one {
      gap: 19px;
      margin-bottom: 19px;
    }

    &--two {
      gap: 15px;
      margin-bottom: 27px;
    }

    &--three {
      gap: 11px;
    }
  }

  &__text {
    max-width: 171px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 14px;
    line-height: 96%;
    letter-spacing: -0.04em;
    color: var(--dark-color);

    @media (max-width: 620px) {
      max-width: 100%;
      font-size: 16px;
    }
  }

  &__link {
    display: block;
    width: 344px;
    height: 100%;
    border-radius: 15px;
    background: var(--accent-color);
    transition: .3s background ease-in-out;

    &:hover {
      background: #2f3c73;
    }

    @media (max-width: 1000px) {
      height: 140px;
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__name {
    font-family: var(--third-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;
    letter-spacing: -0.04em;
    color: var(--white-color);
  }

  &__content {
    height: 100%;
    padding: 20px;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-end;
    height: inherit;
  }
}

.about {
  padding-bottom: 100px;

  @media (max-width: 968px) {
    & {
      padding-bottom: 50px;
    }
  }

  &__title {
    max-width: 1010px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 44px;
    line-height: 101%;
    letter-spacing: -0.01em;
    color: var(--dark-color);
    margin-bottom: 30px;

    span {
      color: var(--accent-color);
    }

    @media (max-width: 1170px) {
      & {
        font-size: 40px;
      }
    }

    @media (max-width: 768px) {
      & {
        font-size: 30px;
      }
    }

    @media (max-width: 968px) {
      & {
        font-size: 34px;
      }

      br {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      & {
        text-align: start;
      }
    }

    @media (max-width: 500px) {
      & {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 375px) {
      font-size: 22px;
    }
  }

  &__descr {
    max-width: 603px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 40px;

    @media (max-width: 500px) {
      & {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }

  &__items {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 1190px) {
      & {
        justify-content: center;
      }
    }
  }

  &__card {
    cursor: pointer;
    background: var(--light-color);
    border-radius: 15px;
    min-height: 142px;
    transition: .3s background ease-in-out;

    &--lg {
      width: 277px;

      @media (max-width: 500px) {
        & {
          width: 100%;
        }
      }
    }

    &--md {
      width: 147px;

      @media (max-width: 500px) {
        & {
          width: calc(100% / 2 - 5px);
        }
      }
    }

    &:hover {
      background: #dcdcdc;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 434px;
    flex-wrap: wrap;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    width: 716px;

    @media (max-width: 1185px) {
      & {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &__content {
    padding: 20px;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 28px;
    line-height: 54%;
    letter-spacing: -0.02em;
    color: var(--dark-color);

    @media (max-width: 500px) {
      & {
        font-size: 22px;
      }
    }

    @media (max-width: 375px) {
      & {
        font-size: 20px;
      }
    }
  }

  &__subname {
    max-width: 237px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: var(--dark-color);

    @media (max-width: 500px) {
      & {
        font-size: 15px;
      }
    }

    @media (max-width: 375px) {
      & {
        font-size: 14px;
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;

    @media (max-width: 1185px) {
      & {
        display: none;
      }
    }
  }
}

.benefits {
  padding-bottom: 100px;

  @media (max-width: 968px) {
    & {
      padding-bottom: 50px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 75px;

    @media (max-width: 968px) {
      & {
        gap: 30px;
      }
    }
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 968px) {
      & {
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  &__title {
    max-width: 400px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 44px;
    line-height: 101%;
    letter-spacing: -0.01em;
    color: var(--dark-color);

    span {
      color: var(--accent-color);
    }

    @media (max-width: 1170px) {
      & {
        font-size: 40px;
      }
    }

    @media (max-width: 768px) {
      & {
        font-size: 30px;
      }
    }

    @media (max-width: 968px) {
      & {
        font-size: 34px;
      }

      br {
        display: none;
      }

      & {
        max-width: 100%;
      }
    }

    @media (max-width: 1000px) {
      & {
        text-align: start;
      }
    }

    @media (max-width: 500px) {
      & {
        font-size: 25px;
      }
    }

    @media (max-width: 375px) {
      font-size: 22px;
    }
  }

  &__place {
    border-radius: 15px;
    background: var(--light-color);
    padding: 16px 31px;
    max-width: 563px;

    @media (max-width: 500px) {
      & {
        padding: 20px;
      }
    }
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 19px;
    line-height: 122%;
    letter-spacing: -0.01em;
    color: var(--dark-color);

    @media (max-width: 768px) {
      & {
        font-size: 17px;
      }
    }

    @media (max-width: 500px) {
      & {
        font-size: 15px;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    max-width: 1040px;

    @media (max-width: 968px) {
      & {
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
      }
    }
  }

  &__items {
    display: flex;
    gap: 38px;
    flex-direction: column;

    @media (max-width: 968px) {
      & {
        gap: 20px;
      }
    }

    &--two {
      gap: 50px;

      @media (max-width: 968px) {
        & {
          gap: 20px;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: stretch;

    @media (max-width: 425px) {
      & {
        flex-direction: column;
        gap: 10px;
      }
    }

    &--one {
      gap: 17px;

      @media (max-width: 425px) {
        & {
          gap: 10px;
        }
      }
    }

    &--two {
      gap: 20px;

      @media (max-width: 425px) {
        & {
          gap: 10px;
        }
      }
    }

    &--three {
      gap: 15px;

      @media (max-width: 425px) {
        & {
          gap: 10px;
        }
      }
    }

    &--four {
      gap: 11px;

      @media (max-width: 425px) {
        & {
          gap: 10px;
        }
      }
    }
  }

  &__name {
    max-width: 403px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: var(--dark-color);

    @media (max-width: 425px) {
      & {
        font-size: 15px;
      }
    }
  }

  &__subname {
    max-width: 403px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: rgba(51, 51, 51, 0.5);

    @media (max-width: 425px) {
      & {
        font-size: 15px;
      }
    }
  }
}

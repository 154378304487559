.nav {
  display: flex;
  align-items: center;
  gap: 80px;
  width: 76%;
  justify-content: space-between;

  @media (max-width: 1070px) {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 0;
      height: 2px;
      background-color: var(--accent-color);
      transition: .3s width cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &:not(:last-child) {
      margin-right: 36px;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 15.4px;
    line-height: 100%;
    text-align: center;
    color: #11101f;
  }
}

.header {
  padding-top: 8px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  @media (max-width: 1070px) {
    padding-top: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.8);
    padding: 8px 12px 8px 24px;

    @media (max-width: 1070px) {
      border-radius: 0px;
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: var(--white-color);
      backdrop-filter: none;
    }
  }
}

.slider {

  &-hero {
    max-width: 570px;
    border-radius: 15px;

    @media (max-width: 620px) {
      & {
        max-width: 100%;
      }
    }

    &__wrapper {
      border-radius: 15px;
    }

    &__slide {
      picture {
        img {
          border-radius: 15px;
        }
      }
    }
  }

  &-reviews {
    width: 100%;

    @media (max-width: 620px) {
      & {
        max-height: 620px;
      }
    }

    @media (max-width: 340px) {
      & {
        max-height: 650px;
      }
    }

    &__slide {
      display: flex;
      align-items: center;
      gap: 9px;
      height: 443px;

      @media (max-width: 1175px) {
        & {
          justify-content: center;
        }
      }

      @media (max-width: 620px) {
        & {
          flex-direction: column-reverse;
          height: 100%;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 281px;
      border-radius: 10px;
      background: #F8F8F8;
      height: 100%;

      @media (max-width: 620px) {
        & {
          max-width: 100%;
        }
      }
    }

    &__contents {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__name {
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 16px;
      line-height: 133%;
      color: var(--dark-color);
    }

    &__subname {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 133%;
      color: rgba(51, 51, 51, 0.5);

      @media (max-width: 620px) {
        & {
          font-size: 15px;
        }
      }
    }

    &__photo {
      max-width: 261px;
      border-radius: 10px;
      padding: 8px 10px;
      background: var(--accent-color);

      picture {
        img {
          border-radius: 6px;
        }
      }

      @media (max-width: 620px) {
        & {
          max-width: 100%;
          height: 20%;
          width: 100%;
          padding: 0;
          border-radius: 0px;
          background: transparent;

          picture {
            img {
              width: 100%;
              height: 250px;
            }
          }
        }
      }

    }
  }
}

.slider-hero .swiper-pagination-bullet {
  background: var(--white-color);
  opacity: 1;
  width: 12px;
  height: 12px;
}

.slider-hero .swiper-pagination-bullet-active {
  background: var(--accent-color);
}

.slider-hero .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px 0 0;
}

.slider-hero .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px;
}

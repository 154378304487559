.hero {
  padding-top: calc(68px + 70px);
  padding-bottom: 100px;

  @media (max-width: 1024px) {
    padding-top: calc(68px + 40px);
  }

  @media (max-width: 968px) {
    padding-bottom: 50px;
  }

  &__content {
    display: flex;
    align-items: stretch;
    gap: 20px;

    @media (max-width: 1000px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 44px;
    line-height: 101%;
    letter-spacing: -0.01em;
    color: var(--dark-color);

    span {
      color: var(--accent-color);
    }

    @media (max-width: 1170px) {
      & {
        font-size: 40px;
      }
    }

    @media (max-width: 768px) {
      & {
        font-size: 30px;
      }
    }

    @media (max-width: 968px) {
      & {
        font-size: 34px;
      }

      br {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      & {
        text-align: start;
      }
    }

    @media (max-width: 500px) {
      & {
        font-size: 25px;
      }
    }

    @media (max-width: 375px) {
      font-size: 22px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: space-between;

    @media (max-width: 1000px) {
      gap: 30px;
    }
  }
}

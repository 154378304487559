.favor {
  padding-bottom: 120px;

  @media (max-width: 968px) {
    & {
      padding-bottom: 50px;
    }
  }

  &__content {
    display: flex;
    gap: 10px;
    border-radius: 20px;
    background: var(--light-color);
    padding: 40px 20px;

    @media (max-width: 425px) {
      padding: 20px;
    }
  }

  &__left {
    @media (max-width: 1185px) {
      & {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }

    @media (max-width: 620px) {
      & {
        align-items: normal;
      }
    }
  }

  &__right {
    max-width: 555px;

    @media (max-width: 1185px) {
      & {
        display: none;
      }
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 44px;
    line-height: 101%;
    letter-spacing: -0.01em;
    color: var(--dark-color);
    margin-bottom: 25px;

    @media (max-width: 1170px) {
      & {
        font-size: 40px;
      }
    }

    @media (max-width: 768px) {
      & {
        font-size: 30px;
      }
    }

    @media (max-width: 968px) {
      & {
        font-size: 34px;
      }

      br {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      & {
        text-align: start;
      }
    }

    @media (max-width: 500px) {
      & {
        font-size: 25px;
      }
    }

    @media (max-width: 375px) {
      font-size: 22px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 13px;
  }

  &__item {
    width: 555px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-radius: 20px;
    background: var(--white-color);
    padding: 19px;

    @media (max-width: 968px) {
      & {
        width: 100%;
      }
    }

    @media (max-width: 500px) {
      & {
        padding: 15px;
      }
    }
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: var(--dark-color);
    margin-bottom: 13px;

    @media (max-width: 500px) {
      & {
        font-size: 15px;
      }
    }

    @media (max-width: 425px) {
      font-size: 14px;
    }
  }

  &__subname {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: rgba(51, 51, 51, 0.5);

    @media (max-width: 500px) {
      & {
        font-size: 14px;
      }
    }

    @media (max-width: 425px) {
      font-size: 13px;
    }
  }

  &__texts {
    &--one {
      max-width: 365px;
    }

    &--two {
      max-width: 400px;
    }

    &--three {
      max-width: 323px;
    }

    &--four {
      max-width: 306px;
    }
  }

  &__icon {
    @media (max-width: 500px) {
      & {
        display: none;
      }
    }
  }
}

.form {

  &-contact {

    &__input {
      border-radius: 15px;
      background: var(--white-color);
      border: none;
      width: 100%;
      padding: 25px 30px 25px 30px;
      font-family: var(--font-family);
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: var(--dark-color);
      margin-bottom: 10px;

      &::placeholder {
        font-family: inherit;
        color: #939393;
      }

      &--icon {
        padding-left: 57px !important;
      }

      @media (max-width: 500px) {
        & {
          font-size: 15px;
          padding: 20px 20px 20px 30px;
        }
      }
    }

    &__box {
      position: relative;
    }

    &__icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 23px;
    }

    &__policy {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 16px;
      line-height: 94%;
      letter-spacing: -0.03em;
      color: rgba(51, 51, 51, 0.5);

      @media (max-width: 500px) {
        & {
          font-size: 14px;
        }
      }
    }

    &__link {
      color: inherit;
      text-decoration: underline;
      text-decoration-skip-ink: none;
    }
  }
}

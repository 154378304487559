:root {
  --font-family: "SF Pro Display", sans-serif;
  --third-family: "Inter", sans-serif;
  --font4: "Manrope", sans-serif;
  --content-width: 1160px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --white-color: #fff;
  --black-color: #000;
  --light-color: #f4f4f4;
  --dark-color: #333;
  --accent-color: #1a2555;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/../fonts/SFProDisplay-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/../fonts/Manrope-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/../fonts/SFProDisplay-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: var(--white-color);
  color: var(--black-color);
}
.page__body::-webkit-scrollbar {
  width: 8px;
  background: #bdbdbd;
}
.page__body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--accent-color);
  transition: 0.7s background;
}

.overflow {
  overflow: hidden;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.header {
  padding-top: 8px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
@media (max-width: 1070px) {
  .header {
    padding-top: 0;
  }
}
.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.8);
  padding: 8px 12px 8px 24px;
}
@media (max-width: 1070px) {
  .header__content {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: var(--white-color);
    backdrop-filter: none;
  }
}

.hero {
  padding-top: 138px;
  padding-bottom: 100px;
}
@media (max-width: 1024px) {
  .hero {
    padding-top: 108px;
  }
}
@media (max-width: 968px) {
  .hero {
    padding-bottom: 50px;
  }
}
.hero__content {
  display: flex;
  align-items: stretch;
  gap: 20px;
}
@media (max-width: 1000px) {
  .hero__content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
}
.hero__title {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 44px;
  line-height: 101%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
}
.hero__title span {
  color: var(--accent-color);
}
@media (max-width: 1170px) {
  .hero__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .hero__title {
    font-size: 30px;
  }
}
@media (max-width: 968px) {
  .hero__title {
    font-size: 34px;
  }
  .hero__title br {
    display: none;
  }
}
@media (max-width: 1000px) {
  .hero__title {
    text-align: start;
  }
}
@media (max-width: 500px) {
  .hero__title {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .hero__title {
    font-size: 22px;
  }
}
.hero__info {
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;
}
@media (max-width: 1000px) {
  .hero__info {
    gap: 30px;
  }
}

.about {
  padding-bottom: 100px;
}
@media (max-width: 968px) {
  .about {
    padding-bottom: 50px;
  }
}
.about__title {
  max-width: 1010px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 44px;
  line-height: 101%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
  margin-bottom: 30px;
}
.about__title span {
  color: var(--accent-color);
}
@media (max-width: 1170px) {
  .about__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .about__title {
    font-size: 30px;
  }
}
@media (max-width: 968px) {
  .about__title {
    font-size: 34px;
  }
  .about__title br {
    display: none;
  }
}
@media (max-width: 1000px) {
  .about__title {
    text-align: start;
  }
}
@media (max-width: 500px) {
  .about__title {
    font-size: 25px;
    margin-bottom: 20px;
  }
}
@media (max-width: 375px) {
  .about__title {
    font-size: 22px;
  }
}
.about__descr {
  max-width: 603px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.5);
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .about__descr {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.about__items {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 10px;
}
@media (max-width: 1190px) {
  .about__items {
    justify-content: center;
  }
}
.about__card {
  cursor: pointer;
  background: var(--light-color);
  border-radius: 15px;
  min-height: 142px;
  transition: 0.3s background ease-in-out;
}
.about__card--lg {
  width: 277px;
}
@media (max-width: 500px) {
  .about__card--lg {
    width: 100%;
  }
}
.about__card--md {
  width: 147px;
}
@media (max-width: 500px) {
  .about__card--md {
    width: calc(50% - 5px);
  }
}
.about__card:hover {
  background: #dcdcdc;
}
.about__left {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 434px;
  flex-wrap: wrap;
}
.about__right {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  width: 716px;
}
@media (max-width: 1185px) {
  .about__right {
    width: 100%;
    justify-content: center;
  }
}
.about__content {
  padding: 20px;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.about__name {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 28px;
  line-height: 54%;
  letter-spacing: -0.02em;
  color: var(--dark-color);
}
@media (max-width: 500px) {
  .about__name {
    font-size: 22px;
  }
}
@media (max-width: 375px) {
  .about__name {
    font-size: 20px;
  }
}
.about__subname {
  max-width: 237px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: var(--dark-color);
}
@media (max-width: 500px) {
  .about__subname {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .about__subname {
    font-size: 14px;
  }
}
.about__img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
@media (max-width: 1185px) {
  .about__img {
    display: none;
  }
}

.benefits {
  padding-bottom: 100px;
}
@media (max-width: 968px) {
  .benefits {
    padding-bottom: 50px;
  }
}
.benefits__content {
  display: flex;
  flex-direction: column;
  gap: 75px;
}
@media (max-width: 968px) {
  .benefits__content {
    gap: 30px;
  }
}
.benefits__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 968px) {
  .benefits__block {
    flex-direction: column;
    gap: 20px;
  }
}
.benefits__title {
  max-width: 400px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 44px;
  line-height: 101%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
}
.benefits__title span {
  color: var(--accent-color);
}
@media (max-width: 1170px) {
  .benefits__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .benefits__title {
    font-size: 30px;
  }
}
@media (max-width: 968px) {
  .benefits__title {
    font-size: 34px;
  }
  .benefits__title br {
    display: none;
  }
  .benefits__title {
    max-width: 100%;
  }
}
@media (max-width: 1000px) {
  .benefits__title {
    text-align: start;
  }
}
@media (max-width: 500px) {
  .benefits__title {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .benefits__title {
    font-size: 22px;
  }
}
.benefits__place {
  border-radius: 15px;
  background: var(--light-color);
  padding: 16px 31px;
  max-width: 563px;
}
@media (max-width: 500px) {
  .benefits__place {
    padding: 20px;
  }
}
.benefits__text {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 19px;
  line-height: 122%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
}
@media (max-width: 768px) {
  .benefits__text {
    font-size: 17px;
  }
}
@media (max-width: 500px) {
  .benefits__text {
    font-size: 15px;
  }
}
.benefits__info {
  display: flex;
  justify-content: space-between;
  max-width: 1040px;
}
@media (max-width: 968px) {
  .benefits__info {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}
.benefits__items {
  display: flex;
  gap: 38px;
  flex-direction: column;
}
@media (max-width: 968px) {
  .benefits__items {
    gap: 20px;
  }
}
.benefits__items--two {
  gap: 50px;
}
@media (max-width: 968px) {
  .benefits__items--two {
    gap: 20px;
  }
}
.benefits__item {
  display: flex;
  align-items: stretch;
}
@media (max-width: 425px) {
  .benefits__item {
    flex-direction: column;
    gap: 10px;
  }
}
.benefits__item--one {
  gap: 17px;
}
@media (max-width: 425px) {
  .benefits__item--one {
    gap: 10px;
  }
}
.benefits__item--two {
  gap: 20px;
}
@media (max-width: 425px) {
  .benefits__item--two {
    gap: 10px;
  }
}
.benefits__item--three {
  gap: 15px;
}
@media (max-width: 425px) {
  .benefits__item--three {
    gap: 10px;
  }
}
.benefits__item--four {
  gap: 11px;
}
@media (max-width: 425px) {
  .benefits__item--four {
    gap: 10px;
  }
}
.benefits__name {
  max-width: 403px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: var(--dark-color);
}
@media (max-width: 425px) {
  .benefits__name {
    font-size: 15px;
  }
}
.benefits__subname {
  max-width: 403px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.5);
}
@media (max-width: 425px) {
  .benefits__subname {
    font-size: 15px;
  }
}

.favor {
  padding-bottom: 120px;
}
@media (max-width: 968px) {
  .favor {
    padding-bottom: 50px;
  }
}
.favor__content {
  display: flex;
  gap: 10px;
  border-radius: 20px;
  background: var(--light-color);
  padding: 40px 20px;
}
@media (max-width: 425px) {
  .favor__content {
    padding: 20px;
  }
}
@media (max-width: 1185px) {
  .favor__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
@media (max-width: 620px) {
  .favor__left {
    align-items: normal;
  }
}
.favor__right {
  max-width: 555px;
}
@media (max-width: 1185px) {
  .favor__right {
    display: none;
  }
}
.favor__title {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 44px;
  line-height: 101%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
  margin-bottom: 25px;
}
@media (max-width: 1170px) {
  .favor__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .favor__title {
    font-size: 30px;
  }
}
@media (max-width: 968px) {
  .favor__title {
    font-size: 34px;
  }
  .favor__title br {
    display: none;
  }
}
@media (max-width: 1000px) {
  .favor__title {
    text-align: start;
  }
}
@media (max-width: 500px) {
  .favor__title {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .favor__title {
    font-size: 22px;
  }
}
.favor__items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 13px;
}
.favor__item {
  width: 555px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border-radius: 20px;
  background: var(--white-color);
  padding: 19px;
}
@media (max-width: 968px) {
  .favor__item {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .favor__item {
    padding: 15px;
  }
}
.favor__name {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: var(--dark-color);
  margin-bottom: 13px;
}
@media (max-width: 500px) {
  .favor__name {
    font-size: 15px;
  }
}
@media (max-width: 425px) {
  .favor__name {
    font-size: 14px;
  }
}
.favor__subname {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.5);
}
@media (max-width: 500px) {
  .favor__subname {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .favor__subname {
    font-size: 13px;
  }
}
.favor__texts--one {
  max-width: 365px;
}
.favor__texts--two {
  max-width: 400px;
}
.favor__texts--three {
  max-width: 323px;
}
.favor__texts--four {
  max-width: 306px;
}
@media (max-width: 500px) {
  .favor__icon {
    display: none;
  }
}

.reviews {
  padding-bottom: 100px;
}
.reviews__content {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.reviews__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reviews__title {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 44px;
  line-height: 101%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
}
@media (max-width: 1170px) {
  .reviews__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .reviews__title {
    font-size: 30px;
  }
}
@media (max-width: 968px) {
  .reviews__title {
    font-size: 34px;
  }
  .reviews__title br {
    display: none;
  }
}
@media (max-width: 1000px) {
  .reviews__title {
    text-align: start;
  }
}
@media (max-width: 500px) {
  .reviews__title {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .reviews__title {
    font-size: 22px;
  }
}
.reviews__navigation {
  display: flex;
  align-items: center;
  gap: 10px;
}
.reviews__prev {
  cursor: pointer;
  background: var(--accent-color);
  border-radius: 100%;
  width: 31px;
  height: 31px;
  transition: 0.3s background ease-in-out;
}
.reviews__prev:hover {
  background: #243479;
}
.reviews__next {
  cursor: pointer;
  background: var(--accent-color);
  border-radius: 100%;
  width: 31px;
  height: 31px;
  transition: 0.3s background ease-in-out;
}
.reviews__next:hover {
  background: #243479;
}

.questions {
  padding-bottom: 100px;
}
@media (max-width: 968px) {
  .questions {
    padding-bottom: 50px;
  }
}
.questions__content {
  display: flex;
  align-items: stretch;
  gap: 10px;
}
.questions__photo {
  max-width: 555px;
}
@media (max-width: 1175px) {
  .questions__photo {
    display: none;
  }
}

.contact {
  padding-bottom: 100px;
}
@media (max-width: 968px) {
  .contact {
    padding-bottom: 50px;
  }
}
.contact__content {
  border-radius: 20px;
  background: #f4f4f4;
  display: flex;
  gap: 53px;
  padding: 37px 20px 36px 36px;
}
@media (max-width: 500px) {
  .contact__content {
    padding: 20px 25px;
  }
}
.contact__photo {
  max-width: 500px;
}
@media (max-width: 1110px) {
  .contact__photo {
    display: none;
  }
}
.contact__title {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 44px;
  line-height: 101%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
  margin-bottom: 25px;
}
@media (max-width: 1170px) {
  .contact__title {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .contact__title {
    font-size: 30px;
  }
}
@media (max-width: 968px) {
  .contact__title {
    font-size: 34px;
  }
  .contact__title br {
    display: none;
  }
}
@media (max-width: 1000px) {
  .contact__title {
    text-align: start;
  }
}
@media (max-width: 500px) {
  .contact__title {
    font-size: 25px;
    margin-bottom: 15px;
  }
}
@media (max-width: 375px) {
  .contact__title {
    font-size: 22px;
  }
}
@media (max-width: 320px) {
  .contact__title {
    font-size: 19px;
  }
}
.contact__title span {
  color: var(--accent-color);
}
.contact__descr {
  max-width: 458px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.5);
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .contact__descr {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .contact__descr br {
    display: none;
  }
}

.footer {
  padding-bottom: 50px;
}
.footer__content {
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer__row {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .footer__row {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }
}
.footer__date {
  display: flex;
  align-items: center;
  gap: 50px;
}
@media (max-width: 600px) {
  .footer__date {
    flex-direction: column;
    gap: 25px;
    align-items: center;
    justify-content: center;
  }
}
.footer__info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer__information {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
@media (max-width: 600px) {
  .footer__information {
    align-items: center;
    justify-content: center;
  }
}
.footer__text {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
}
.footer__number {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
}
.footer__mail {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
}
.footer__link {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 15px;
  line-height: 157%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--dark-color);
}
.footer__copyright {
  max-width: 1028px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: rgba(51, 51, 51, 0.5);
}
@media (max-width: 1024px) {
  .footer__copyright {
    text-align: center;
  }
}
.footer__buttons {
  display: flex;
  gap: 10px;
}
@media (max-width: 600px) {
  .footer__buttons {
    flex-direction: column;
  }
}

.nav {
  display: flex;
  align-items: center;
  gap: 80px;
  width: 76%;
  justify-content: space-between;
}
@media (max-width: 1070px) {
  .nav {
    display: none;
  }
}
.nav__list {
  display: flex;
  align-items: center;
}
.nav__item {
  position: relative;
}
.nav__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 2px;
  background-color: var(--accent-color);
  transition: 0.3s width cubic-bezier(0.39, 0.575, 0.565, 1);
}
.nav__item:not(:last-child) {
  margin-right: 36px;
}
.nav__item:hover::after {
  width: 100%;
}
.nav__link {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 15.4px;
  line-height: 100%;
  text-align: center;
  color: #11101f;
}

.btn--form {
  background: var(--accent-color);
  border-radius: 30px;
  font-family: var(--font4);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: var(--white-color);
  padding: 11px 15px;
  transition: 0.3s background ease-in-out;
}
.btn--form:hover {
  background: #243479;
}
.btn--formm {
  background: var(--white-color);
  border-radius: 30px;
  font-family: var(--font4);
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: var(--black-color);
  padding: 11px 15px;
  transition: 0.3s background ease-in-out;
  width: 100%;
}
.btn--formm:hover {
  background: #fff;
}
.btn--cost {
  background: var(--accent-color);
  border-radius: 15px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 122%;
  letter-spacing: -0.01em;
  color: var(--white-color);
  padding: 17px 0;
  width: 100%;
  transition: 0.3s background ease-in-out;
}
@media (max-width: 1185px) {
  .btn--cost {
    width: 555px;
  }
}
@media (max-width: 620px) {
  .btn--cost {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .btn--cost {
    font-size: 17px;
  }
}
@media (max-width: 375px) {
  .btn--cost {
    font-size: 15px;
  }
}
.btn--cost:hover {
  background: #243479;
}
.btn--submit {
  background: var(--accent-color);
  border-radius: 15px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: var(--white-color);
  padding: 27px 0;
  width: 100%;
  transition: 0.3s background ease-in-out;
  margin-bottom: 25px;
}
.btn--submit:hover {
  background: #243479;
}
@media (max-width: 500px) {
  .btn--submit {
    font-size: 15px;
    padding: 15px 0;
  }
}
.btn--download {
  background: var(--accent-color);
  border-radius: 15px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 122%;
  letter-spacing: -0.01em;
  color: var(--white-color);
  padding: 25px 0;
  width: 231px;
  transition: 0.3s background ease-in-out;
}
.btn--download:hover {
  background: #243479;
}
@media (max-width: 500px) {
  .btn--download {
    font-size: 15px;
    padding: 15px 0;
  }
}

.slider-hero {
  max-width: 570px;
  border-radius: 15px;
}
@media (max-width: 620px) {
  .slider-hero {
    max-width: 100%;
  }
}
.slider-hero__wrapper {
  border-radius: 15px;
}
.slider-hero__slide picture img {
  border-radius: 15px;
}
.slider-reviews {
  width: 100%;
}
@media (max-width: 620px) {
  .slider-reviews {
    max-height: 620px;
  }
}
@media (max-width: 340px) {
  .slider-reviews {
    max-height: 650px;
  }
}
.slider-reviews__slide {
  display: flex;
  align-items: center;
  gap: 9px;
  height: 443px;
}
@media (max-width: 1175px) {
  .slider-reviews__slide {
    justify-content: center;
  }
}
@media (max-width: 620px) {
  .slider-reviews__slide {
    flex-direction: column-reverse;
    height: 100%;
  }
}
.slider-reviews__info {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 281px;
  border-radius: 10px;
  background: #F8F8F8;
  height: 100%;
}
@media (max-width: 620px) {
  .slider-reviews__info {
    max-width: 100%;
  }
}
.slider-reviews__contents {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.slider-reviews__name {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 133%;
  color: var(--dark-color);
}
.slider-reviews__subname {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 133%;
  color: rgba(51, 51, 51, 0.5);
}
@media (max-width: 620px) {
  .slider-reviews__subname {
    font-size: 15px;
  }
}
.slider-reviews__photo {
  max-width: 261px;
  border-radius: 10px;
  padding: 8px 10px;
  background: var(--accent-color);
}
.slider-reviews__photo picture img {
  border-radius: 6px;
}
@media (max-width: 620px) {
  .slider-reviews__photo {
    max-width: 100%;
    height: 20%;
    width: 100%;
    padding: 0;
    border-radius: 0px;
    background: transparent;
  }
  .slider-reviews__photo picture img {
    width: 100%;
    height: 250px;
  }
}

.slider-hero .swiper-pagination-bullet {
  background: var(--white-color);
  opacity: 1;
  width: 12px;
  height: 12px;
}

.slider-hero .swiper-pagination-bullet-active {
  background: var(--accent-color);
}

.slider-hero .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 10px 0 0;
}

.slider-hero .swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px;
}

.advantages {
  display: flex;
  align-items: center;
  gap: 19px;
}
@media (max-width: 1000px) {
  .advantages {
    justify-content: center;
  }
}
@media (max-width: 620px) {
  .advantages {
    flex-direction: column;
  }
}
.advantages__item {
  display: flex;
  align-items: stretch;
}
.advantages__item--one {
  gap: 19px;
  margin-bottom: 19px;
}
.advantages__item--two {
  gap: 15px;
  margin-bottom: 27px;
}
.advantages__item--three {
  gap: 11px;
}
.advantages__text {
  max-width: 171px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 14px;
  line-height: 96%;
  letter-spacing: -0.04em;
  color: var(--dark-color);
}
@media (max-width: 620px) {
  .advantages__text {
    max-width: 100%;
    font-size: 16px;
  }
}
.advantages__link {
  display: block;
  width: 344px;
  height: 100%;
  border-radius: 15px;
  background: var(--accent-color);
  transition: 0.3s background ease-in-out;
}
.advantages__link:hover {
  background: #2f3c73;
}
@media (max-width: 1000px) {
  .advantages__link {
    height: 140px;
  }
}
@media (max-width: 500px) {
  .advantages__link {
    width: 100%;
  }
}
.advantages__name {
  font-family: var(--third-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 110%;
  letter-spacing: -0.04em;
  color: var(--white-color);
}
.advantages__content {
  height: 100%;
  padding: 20px;
}
.advantages__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
  height: inherit;
}

.accordion {
  width: 575px;
  margin-bottom: 8px;
}
@media (max-width: 1175px) {
  .accordion {
    width: 100%;
  }
}
.accordion__control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 15px 15px 15px 20px;
  border-radius: 10px;
  background: #f8f8f8;
  border: none;
}
.accordion__control::-webkit-details-marker {
  display: none;
}
.accordion__title {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 137%;
  color: var(--dark-color);
}
@media (max-width: 600px) {
  .accordion__title {
    font-size: 14px;
    text-align: left;
  }
}
@media (max-width: 500px) {
  .accordion__title {
    max-width: 315px;
    text-align: left;
  }
}
@media (max-width: 425px) {
  .accordion__title {
    max-width: 260px;
    text-align: left;
  }
}
@media (max-width: 375px) {
  .accordion__title {
    max-width: 210px;
    text-align: left;
  }
}
.accordion__icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: var(--accent-color);
}
.accordion__icon::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 2px;
  background-color: var(--white-color);
  transform: translate(-50%, -50%);
}
.accordion__icon::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 50%;
  background-color: var(--white-color);
  transform: translate(-50%, -50%);
}
.accordion[open] .accordion__icon {
  transform: rotate(45deg);
}
.accordion__control {
  width: 100%;
  cursor: pointer;
}
.accordion__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0 20px;
  will-change: max-height;
  transition: all 0.3s ease-out;
  box-sizing: content-box;
}
.accordion__icon {
  transition: transform 0.3s ease-out, background 0.3s ease-in-out;
}
.accordion__control {
  width: 100%;
  cursor: pointer;
}
.accordion__content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0 17px 20px 20px;
  will-change: max-height;
  transition: all 0.3s ease-out;
  box-sizing: content-box;
  background: #f8f8f8;
  border-radius: 10px;
  margin-top: -15px;
}
.accordion__descr {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 117%;
  color: rgba(51, 51, 51, 0.5);
}
@media (max-width: 425px) {
  .accordion__descr {
    font-size: 14px;
  }
}
.accordion__icon {
  transition: transform 0.3s ease-out;
}

.open .accordion__icon {
  transform: rotate(45deg);
  background: #243479;
}

.open .accordion__content {
  opacity: 1;
  padding: 20px;
}

.form-contact__input {
  border-radius: 15px;
  background: var(--white-color);
  border: none;
  width: 100%;
  padding: 25px 30px 25px 30px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: var(--dark-color);
  margin-bottom: 10px;
}
.form-contact__input::placeholder {
  font-family: inherit;
  color: #939393;
}
.form-contact__input--icon {
  padding-left: 57px !important;
}
@media (max-width: 500px) {
  .form-contact__input {
    font-size: 15px;
    padding: 20px 20px 20px 30px;
  }
}
.form-contact__box {
  position: relative;
}
.form-contact__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 23px;
}
.form-contact__policy {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 94%;
  letter-spacing: -0.03em;
  color: rgba(51, 51, 51, 0.5);
}
@media (max-width: 500px) {
  .form-contact__policy {
    font-size: 14px;
  }
}
.form-contact__link {
  color: inherit;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

@media (min-width: 1070px) {
  .mobile {
    display: none;
  }
}

.mobile div {
  display: block;
  position: fixed;
  top: 8px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999999;
  cursor: pointer;
  box-sizing: content-box;
}

.mobile div span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 4px;
  background-color: var(--accent-color);
  transition-duration: 0.4s;
}

.mobile div span:nth-child(1) {
  top: 14px;
}

.mobile div span:nth-child(2) {
  top: 23px;
}

.mobile div span:nth-child(3) {
  bottom: 14px;
}

.mobile div.active span:nth-child(1) {
  transform: translate(-15px, 9px) rotate(-45deg);
  background-color: var(--white-color);
}

.mobile div.active span:nth-child(2) {
  transition-duration: 0s;
  opacity: 0;
}

.mobile div.active span:nth-child(3) {
  transform: translate(-15px, -9px) rotate(45deg);
  background-color: var(--white-color);
}

.mobile nav {
  display: block;
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999990;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mobile nav.open {
  background-color: var(--accent-color);
  width: 100%;
  height: 100%;
  right: 0px;
  bottom: 0px;
  border-radius: 0;
}

.mobile nav ul {
  display: none;
}

.mobile nav ul.show {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.mobile ul li:not(:last-child) {
  margin-bottom: 22px;
}

.mobile ul li a {
  font-family: var(--third-family);
  font-weight: 500;
  font-size: 22px;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: #fff;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.policy {
  padding-top: 98px;
  padding-bottom: 30px;
  color: #1c1c1c;
}
.policy__title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.policy__subtitle {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}
.policy__text {
  font-family: var(--second-family);
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 999999;
}

.modal {
  border-radius: 20px;
  padding: 0 20px;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.modal-overlay--visible {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.modal--visible {
  display: block;
}

@media (max-width: 1024px) {
  .modals .contact__title {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 1024px) {
  .modals .contact__descr {
    margin-bottom: 10px !important;
  }
}
.reviews {
  padding-bottom: 100px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 44px;
    line-height: 101%;
    letter-spacing: -0.01em;
    color: var(--dark-color);

    @media (max-width: 1170px) {
      & {
        font-size: 40px;
      }
    }

    @media (max-width: 768px) {
      & {
        font-size: 30px;
      }
    }

    @media (max-width: 968px) {
      & {
        font-size: 34px;
      }

      br {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      & {
        text-align: start;
      }
    }

    @media (max-width: 500px) {
      & {
        font-size: 25px;
      }
    }

    @media (max-width: 375px) {
      font-size: 22px;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__prev {
    cursor: pointer;
    background: var(--accent-color);
    border-radius: 100%;
    width: 31px;
    height: 31px;
    transition: .3s background ease-in-out;

    &:hover {
      background: #243479;
    }
  }

  &__next {
    cursor: pointer;
    background: var(--accent-color);
    border-radius: 100%;
    width: 31px;
    height: 31px;
    transition: .3s background ease-in-out;

    &:hover {
      background: #243479;
    }
  }
}

.btn {

  &--form {
    background: var(--accent-color);
    border-radius: 30px;
    font-family: var(--font4);
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: var(--white-color);
    padding: 11px 15px;
    transition: .3s background ease-in-out;

    &:hover {
      background: #243479;
    }
  }

  &--formm {
    background: var(--white-color);
    border-radius: 30px;
    font-family: var(--font4);
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: var(--black-color);
    padding: 11px 15px;
    transition: .3s background ease-in-out;
    width: 100%;

    &:hover {
      background: #fff;
    }
  }

  &--cost {
    background: var(--accent-color);
    border-radius: 15px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 122%;
    letter-spacing: -0.01em;
    color: var(--white-color);
    padding: 17px 0;
    width: 100%;
    transition: .3s background ease-in-out;

    @media (max-width: 1185px) {
      width: 555px;
    }

    @media (max-width: 620px) {
      width: 100%;
    }

    @media (max-width: 500px) {
      font-size: 17px;
    }

    @media (max-width: 375px) {
      font-size: 15px;
    }

    &:hover {
      background: #243479;
    }
  }

  &--submit {
    background: var(--accent-color);
    border-radius: 15px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: var(--white-color);
    padding: 27px 0;
    width: 100%;
    transition: .3s background ease-in-out;
    margin-bottom: 25px;

    &:hover {
      background: #243479;
    }

    @media (max-width: 500px) {
      font-size: 15px;
      padding: 15px 0;
    }
  }

  &--download {
    background: var(--accent-color);
    border-radius: 15px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 20px;
    line-height: 122%;
    letter-spacing: -0.01em;
    color: var(--white-color);
    padding: 25px 0;
    width: 231px;
    transition: .3s background ease-in-out;

    &:hover {
      background: #243479;
    }

    @media (max-width: 500px) {
      font-size: 15px;
      padding: 15px 0;
    }
  }
}

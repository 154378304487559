.contact {
  padding-bottom: 100px;

  @media (max-width: 968px) {
    & {
      padding-bottom: 50px;
    }
  }

  &__content {
    border-radius: 20px;
    background: #f4f4f4;
    display: flex;
    gap: 53px;
    padding: 37px 20px 36px 36px;

    @media (max-width: 500px) {
      & {
        padding: 20px 25px;
      }
    }
  }

  &__photo {
    max-width: 500px;

    @media (max-width: 1110px) {
      & {
        display: none;
      }
    }
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 44px;
    line-height: 101%;
    letter-spacing: -0.01em;
    color: var(--dark-color);
    margin-bottom: 25px;

    @media (max-width: 1170px) {
      & {
        font-size: 40px;
      }
    }

    @media (max-width: 768px) {
      & {
        font-size: 30px;
      }
    }

    @media (max-width: 968px) {
      & {
        font-size: 34px;
      }

      br {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      & {
        text-align: start;
      }
    }

    @media (max-width: 500px) {
      & {
        font-size: 25px;
        margin-bottom: 15px;
      }
    }

    @media (max-width: 375px) {
      font-size: 22px;
    }

    @media (max-width: 320px) {
      font-size: 19px;
    }

    span {
      color: var(--accent-color);
    }
  }

  &__descr {
    max-width: 458px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 94%;
    letter-spacing: -0.03em;
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 40px;

    @media (max-width: 500px) {
      & {
        font-size: 15px;
        margin-bottom: 20px;
      }

      br {
        display: none;
      }
    }
  }
}

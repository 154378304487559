.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 999999;
}

.modal {
  border-radius: 20px;
  padding: 0 20px;
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.modal-overlay--visible {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.modal--visible {
  display: block;
}

.modals .contact__title {
  @media (max-width: 1024px) {
    margin-bottom: 10px !important;
  }
}

.modals .contact__descr {
  @media (max-width: 1024px) {
    margin-bottom: 10px !important;
  }
}

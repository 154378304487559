.accordion {
  width: 575px;
  margin-bottom: 8px;

  @media (max-width: 1175px) {
    width: 100%;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 15px 15px 15px 20px;
    border-radius: 10px;
    background: #f8f8f8;
    border: none;
  }

  &__control::-webkit-details-marker {
    display: none;
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 137%;
    color: var(--dark-color);

    @media (max-width: 600px) {
      font-size: 14px;
      text-align: left;
    }

    @media (max-width: 500px) {
      max-width: 315px;
      text-align: left;
    }

    @media (max-width: 425px) {
      max-width: 260px;
      text-align: left;
    }

    @media (max-width: 375px) {
      max-width: 210px;
      text-align: left;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: var(--accent-color);
  }

  &__icon::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 2px;
    background-color: var(--white-color);
    transform: translate(-50%, -50%);
  }

  &__icon::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2px;
    height: 50%;
    background-color: var(--white-color);
    transform: translate(-50%, -50%);
  }

  &[open] &__icon {
    transform: rotate(45deg);
  }

  &__control {
    width: 100%;
    cursor: pointer;
  }

  &__content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 20px;
    will-change: max-height;
    transition: all 0.3s ease-out;
    box-sizing: content-box;
  }

  &__icon {
    transition: transform 0.3s ease-out, background .3s ease-in-out;
  }

  &__control {
    width: 100%;
    cursor: pointer;
  }

  &__content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0 17px 20px 20px;
    will-change: max-height;
    transition: all 0.3s ease-out;
    box-sizing: content-box;
    background: #f8f8f8;
    border-radius: 10px;
    margin-top: -15px;
  }

  &__descr {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 117%;
    color: rgba(51, 51, 51, 0.5);

    @media (max-width: 425px) {
      font-size: 14px;
    }
  }

  &__icon {
    transition: transform 0.3s ease-out;
  }
}

.open .accordion__icon {
  transform: rotate(45deg);
  background: #243479;
}

.open .accordion__content {
  opacity: 1;
  padding: 20px;
}
